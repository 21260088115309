/* eslint-disable max-len */
import { PureComponent } from 'react';

import './CheckmarkIcon.style';

/** @namespace Scandipwa/Component/CheckmarkIcon/Component */
export class CheckmarkIconComponent extends PureComponent {
    /*
     * New method to render checkmark icon
     */
    render() {
        return (
            <svg block="CheckmarkIcon" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8334 0.179321L13.0824 1.42837L4.92253 9.58824L0.929321 5.59503L2.17836 4.34599L4.92253 7.09016L11.8334 0.179321Z" />
            </svg>
        );
    }
}

export default CheckmarkIconComponent;
