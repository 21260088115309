/* eslint-disable comma-dangle */
import {
    FieldSelectComponent as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    /**
     * Overridden to add isNotSelected
     */
    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, options, handleSelectListOptionClick, isSelectedOptionAvailable
        } = this.props;
        const fieldValue = 'value' in attr ? attr.value : attr.defaultValue;

        const isNotSelected = options.every(
            (option) => option.isPlaceholder || String(option.value) !== String(fieldValue)
        );

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isDisabled: !isSelectedOptionAvailable, isNotSelected } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { options.map(this.renderNativeOption.bind(this)) }
            </select>
        );
    }
}

export default FieldSelectComponent;
